import { PaginationType } from 'client/widget-components';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { featureFlags } from '@shared-services/feature-flags';
import { ImageLayout } from './SlideContent';
import {
    BULLET_BACKGROUND_COLOR,
    OVERLAY_COLOR,
    SECONDARY_COLOR,
} from './sliderCommonStyles';
import {
    SliderLayoutTypeToDesignData,
    sliderLayouts,
} from './sliderCommonTypes';

export const sliderlayoutTypeToDesignData: () => SliderLayoutTypeToDesignData =
    () => {
        const imageToFillAvailableSpace = featureFlags.getBoolean(
            'runtime.ssr.slider.image.fillAvailableSpace.enabled',
            false
        );
        return {
            [sliderLayouts.LAYOUT_1]: {
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            width: '100%',
                        },
                    },
                    slide_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            width: '100%',
                        },
                    },
                    slide_overlay: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: OVERLAY_COLOR,
                        },
                    },
                    slide_contentContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 0,
                            bottom: 35,
                            paddingBlockStart: 35,
                            paddingInline: 24,
                            top: 60,
                            right: 0,
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: 'center',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            top: 0,
                        },
                    },
                    slide_title: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'white',
                        },
                    },
                    slide_desc: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'white',
                        },
                    },

                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            bottom: 24,
                            width: '100%',
                        },
                    },
                    pagination_buttonBullet: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: BULLET_BACKGROUND_COLOR,
                        },
                    },
                    pagination_buttonBulletActive: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: 'currentColor',
                            color: 'white',
                        },
                    },
                    pagination_buttonArrow: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'white',
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_2]: {
                paginationType: PaginationType.ARROWS,
                defaultSlotsInFrame: 3,
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            paddingInline: 59,
                            position: 'relative',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            paddingInline: 15,
                        },
                    },
                    slidesContainer: {
                        [RuntimeMediaQuery.MOBILE]: {
                            height: '100%',
                        },
                    },
                    slide_mediaContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            borderRadius: 16,
                        },
                    },
                    slide_overlay: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: OVERLAY_COLOR,
                        },
                    },
                    slide_contentContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 0,
                            bottom: 30,
                            right: 0,
                            alignItems: 'center',
                            flexDirection: 'column',
                            textAlign: 'center',
                            paddingBlockStart: 30,
                            paddingInline: 24,
                        },
                    },
                    slide_title: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'white',
                        },
                    },
                    slide_desc: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'white',
                        },
                    },

                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0,
                        },
                    },
                    pagination_buttonArrow: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'black',
                        },
                    },
                    pagination_buttonBullet: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: BULLET_BACKGROUND_COLOR,
                        },
                    },
                    pagination_buttonBulletActive: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: 'currentColor',
                            color: 'black',
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_3]: {
                paginationType: PaginationType.ARROWS,
                imageLayout: ImageLayout.AS_ELEMENT,
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            paddingInline: 45,
                            position: 'relative',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            paddingInline: 0,
                            position: 'relative',
                        },
                    },
                    slide_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                        },
                    },
                    slide_mediaContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            borderRadius: '50%',
                            aspectRatio: '1/1',
                            flexBasis: '39%',
                        },
                    },
                    slide_contentContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            paddingInline: 32,
                        },
                    },
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 28,
                            right: 28,
                            bottom: 0,
                            top: 0,
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            left: 0,
                            right: 0,
                        },
                    },
                    pagination_buttonBullet: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: BULLET_BACKGROUND_COLOR,
                        },
                    },
                    pagination_buttonBulletActive: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: 'currentColor',
                            color: 'black',
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_3_B]: {
                paginationType: PaginationType.ARROWS,
                imageLayout: ImageLayout.AS_CONTENT_ELEMENT,
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            paddingInline: 45,
                            position: 'relative',
                            paddingTop: 32,
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            paddingInline: 0,
                            position: 'relative',
                        },
                    },
                    slide_mediaContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            borderRadius: '50%',
                            aspectRatio: '1/1',
                            position: 'relative',
                            ...(imageToFillAvailableSpace
                                ? {
                                      flex: 1,
                                  }
                                : {
                                      flexBasis: '39%',
                                  }),
                            flexShrink: 0,
                            order: -1,
                            ':empty': {
                                display: 'none',
                            },
                            marginBottom: 32,
                        },
                    },
                    slide_media: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            bottom: 0,
                            top: 0,
                            left: 0,
                            right: 0,
                        },
                    },
                    slide_contentContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            paddingInline: 32,
                            height: '100%',
                            justifyContent: 'start',
                        },
                    },
                    slide_title: {
                        common: {
                            marginBottom: 0,
                        },
                    },
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 28,
                            right: 28,
                            bottom: 0,
                            top: 0,
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            left: 0,
                            right: 0,
                        },
                    },
                    slide_button: {
                        root: {
                            [RuntimeMediaQuery.COMMON]: {
                                marginTop: 0,
                            },
                        },
                    },
                    pagination_buttonBullet: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: BULLET_BACKGROUND_COLOR,
                        },
                    },
                    pagination_buttonBulletActive: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: 'currentColor',
                            color: 'black',
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_4]: {
                paginationType: PaginationType.THUMBS,
                strictSlotsInFrame: 1,

                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 20,
                            alignItems: 'stretch',
                        },
                    },
                    slide_overlay: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: OVERLAY_COLOR,
                        },
                    },
                    slide_contentContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            top: 0,
                            right: 0,
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: 20,
                            textAlign: 'center',
                        },
                    },
                    slide_title: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'white',
                        },
                    },
                    slide_desc: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'white',
                        },
                    },

                    pagination_thumbsContainer: {
                        [RuntimeMediaQuery.MOBILE]: {
                            overflowX: 'auto',
                            justifyContent: 'start',
                        },
                    },
                    pagination_buttonThumb: {
                        [RuntimeMediaQuery.COMMON]: {
                            flexBasis: 80,
                            flexGrow: 0,
                            flexShrink: 1,
                            width: 'revert',
                            borderWidth: 4,
                            backgroundOrigin: 'border-box',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            flexBasis: 80,
                            flexShrink: 0,
                        },
                    },
                    pagination_buttonThumbActive: {
                        [RuntimeMediaQuery.COMMON]: {
                            borderColor: '#000',
                        },
                    },
                    pagination_buttonArrow: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'white',
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_5]: {
                imageLayout: ImageLayout.AS_ELEMENT,
                defaultSlotsInFrame: 3,
                styles: {
                    slide_mediaContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            ...(imageToFillAvailableSpace
                                ? {
                                      flex: 1,
                                  }
                                : {
                                      height: '46%',
                                      minHeight: '46%',
                                  }),
                            borderRadius: 16,
                            marginBlockEnd: 24,
                        },
                    },
                    slide_contentContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            textAlign: 'center',
                            alignItems: 'center',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            alignItems: 'center',
                            textAlign: 'center',
                            paddingBlock: 0,
                            paddingInline: 24,
                        },
                    },
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                    ...(imageToFillAvailableSpace && {
                        slide_container: {
                            [RuntimeMediaQuery.COMMON]: {
                                display: 'flex',
                                flexDirection: 'column',
                            },
                        },
                        slidesContainer: {
                            [RuntimeMediaQuery.COMMON]: {
                                paddingBlockEnd: 56,
                            },
                        },
                    }),
                    slide_media: {
                        [RuntimeMediaQuery.COMMON]: {
                            width: '100%',
                        },
                    },
                    pagination_buttonBullet: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: SECONDARY_COLOR,
                        },
                    },
                    pagination_buttonBulletActive: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: 'currentColor',
                            color: 'black',
                        },
                    },
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            marginBlockEnd: 24,
                            position: 'absolute',
                            width: '100%',
                            bottom: 0,
                        },
                    },
                    pagination_buttonArrow: {
                        [RuntimeMediaQuery.COMMON]: {
                            color: 'black',
                        },
                    },
                },
            },

            [sliderLayouts.LAYOUT_6]: {
                imageLayout: ImageLayout.AS_ELEMENT,
                slideAnimationDirection: 'vertical',
                strictSlotsInFrame: 1,
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            gap: 24,
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            flexDirection: 'column',
                        },
                    },
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            order: 0,
                            flexDirection: 'column',
                            paddingInlineStart: 2,
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            flexDirection: 'row',
                            paddingInlineStart: 0,
                            paddingBlockEnd: 2,
                        },
                    },

                    pagination_buttonBullet: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: SECONDARY_COLOR,
                        },
                    },
                    pagination_buttonBulletActive: {
                        [RuntimeMediaQuery.COMMON]: {
                            backgroundColor: 'currentColor',
                            color: 'black',
                        },
                    },
                    slidesContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            flexGrow: 1,
                        },
                    },
                    slide_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            display: 'flex',
                            gap: 32,
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            flexDirection: 'column',
                            gap: 24,
                        },
                    },

                    slide_contentContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            paddingBlock: 24,
                            flexDirection: 'column',
                            width: '61%',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            width: 'auto',
                            alignItems: 'center',
                            textAlign: 'center',
                            flex: 'revert',
                            paddingBlock: 0,
                            paddingInline: 24,
                        },
                    },
                    slide_mediaContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            width: '37%',
                            borderRadius: 16,
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            width: 'auto',
                            flex: 'revert',
                            flexBasis: '55%',
                        },
                    },
                },
            },
        };
    };
