import { isObjectEmpty } from 'client/utils';
import { PRODUCT_ONE_TIME_PAYMENT_PLAN_ID } from 'client/widget-components/ecom/consts';
import CustomizationsState from 'client/widget-components/ecom/stores/CustomizationsState';
import {
    DEFAULT_VARIATION_ID,
    ProductState,
} from 'client/widget-components/ecom/stores/ProductState';
import {
    ProductEntity,
    ProductPaymentPlan,
} from 'client/widget-components/ecom/stores/types';
import { ProductCustomization } from 'client/widget-components/ecom/types/EcomSDK';
import { MouseEvent } from 'react';
import { context } from '@shared-services/context-service';
import { logger } from '@shared-services/log-service';

export type ComponentProps = {
    isDisabled: boolean;
    isSubscribe: boolean;
};

export const useAddToCart = ({
    product,
    productState,
}: {
    product: ProductEntity;
    productState: ProductState;
}) => {
    if (isObjectEmpty(product)) {
        return { elementAttributes: {}, componentProps: {} };
    }
    const { itemId, productId, price, internal_quantity } = product;

    const { customizations } = productState;

    const quantityEnabled = !!internal_quantity || internal_quantity === 0;
    const shouldDisable = quantityEnabled && internal_quantity! <= 0;
    const isSubscribe = getIsSubscribe(productState.selectedPaymentPlan);

    const onClick = async (e: MouseEvent<HTMLElement>) => {
        if (
            context.isEditor ||
            !window.dmAPI ||
            !window._ecomSDK ||
            shouldDisable
        ) {
            return;
        }

        e.stopPropagation();

        customizations.validateAll();
        if (!customizations.isValid()) {
            return;
        }

        try {
            const payload = {
                product_id: productId,
                variation_id:
                    productState.selectedVariation?.identifier ||
                    DEFAULT_VARIATION_ID,
                quantity: 1,
                product_customizations:
                    getAddProductCustomizationsPayload(customizations),
            };

            if (isSubscribe) {
                await window._ecomSDK?.buyProduct?.({
                    ...payload,
                    plan_id: productState.selectedPaymentPlan?.id || '',
                });
            } else {
                await window._ecomSDK?.addProduct?.(payload);
            }
        } catch (error) {
            const objectToLog = {
                message: `Error in window._ecomSDK?.${
                    isSubscribe ? 'buyProduct' : 'addProduct'
                }`,
                tags: [
                    '__new-runtime__',
                    'native-ecom',
                    isSubscribe ? 'subscribe-to-product' : 'add-items-to-cart',
                ],
                errorMessage: error,
            };
            logger.error(objectToLog);
        }
    };

    const elementAttributes = {
        'data-item-id': itemId,
        'data-item-price': price,
        'data-auto': 'addToCart',
        className: 'snipcart-add-item',
        onClick,
    };

    const componentProps: ComponentProps = {
        isDisabled: shouldDisable,
        isSubscribe,
    };

    return { elementAttributes, componentProps };
};

const getIsSubscribe = (paymentPlan: ProductPaymentPlan | null) =>
    !!paymentPlan && paymentPlan.id != PRODUCT_ONE_TIME_PAYMENT_PLAN_ID;

const getAddProductCustomizationsPayload = (
    customizations: CustomizationsState
): ProductCustomization[] => {
    return customizations.list.map((customization) => ({
        id: customization.id,
        type: 'TEXT', // Will be available in productView
        value: customizations.values[customization.id],
    }));
};
