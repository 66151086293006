import { RuntimeWidget } from 'client/types';
import { Div } from 'client/widget-components/basicComponents';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import Customizations from 'client/widget-components/ecom/stores/CustomizationsState';
import { CustomizationType } from 'client/widget-components/ecom/stores/types';
import React from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { context } from '@shared-services/context-service';
import { ecomObserver } from '@shared-services/ecom-service';
import { localizationService } from '@shared-services/localization-service';
import RuntimeProductCustomizationTextField from './RuntimeProductCustomizationsFields/RuntimeProductCustomizationTextField';
import {
    ProductCustomizationsProps,
    ProductCustomizationsStyles,
} from './RuntimeProductCustomizationsTypes';

const { str } = localizationService;

const RuntimeProductCustomizations: RuntimeWidget<
    ProductCustomizationsProps
> = ({
    productState: { customizations: customizationsState },
    _styles: styles,
}) => {
    if (customizationsState.isEmpty()) {
        if (context.isEditor) {
            return (
                <div data-auto='product-customizations-placeholder'>
                    {str(
                        'ui.runtimessr.productCustomizations.noCustomizations'
                    )}
                </div>
            );
        }
        return <div />;
    }

    return (
        <Div
            styles={[wrapperDefaultStyles, styles?.wrapper]}
            data-auto='product-customizations'
            data-grab='product-customizations'
        >
            {customizationsState.list.map((customization) =>
                getCustomizationField(
                    customizationsState,
                    customization,
                    styles
                )
            )}
        </Div>
    );
};

function getCustomizationField(
    customizationsState: Customizations,
    customization: CustomizationType,
    styles: ProductCustomizationsStyles | undefined
) {
    // TODO: return the correct field based on the customization type
    return (
        <RuntimeProductCustomizationTextField
            {...customization}
            key={customization.id}
            styles={styles}
            value={customizationsState.values[customization.id]}
            error={customizationsState.errors[customization.id]}
            onChange={customizationsState.updateValue}
            validateField={customizationsState.validate}
        />
    );
}

const wrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        width: '100%',
    },
};

const RuntimeProductCustomizationsWidget = withErrorBoundary({
    Comp: ecomObserver(RuntimeProductCustomizations),
    componentName: 'ProductCustomizations',
    logProps: true,
});

export default RuntimeProductCustomizationsWidget;
